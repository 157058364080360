<template>
  <div>
    <div
      :class="`video-item ${sign ? 'yjg-padding' : 'zc-padding'}`"
      :style="`height:${boxHeight}px; margin-top:${boxMarginTop}px`"
    >
      <div id="monitor-box" :key="videoItem.name" class="myPlayer-video" />
      <!-- 底部操作条 -->
      <div
        :class="`video-footer f f-a-c f-j-s-b  ${sign ? 'yjg-dw' : 'zc-dw'}`"
      >
        <el-tooltip
          :visible-arrow="true"
          effect="dark"
          :content="videoItem.name"
          placement="bottom"
        >
          <div :class="`footer-title color-41a5d8 c-d text-ellipsis`">
            {{ videoItem.name }}
          </div>
        </el-tooltip>
        <div class="f f-a-c">
          <div :class="`footer-two f f-a-c color-41a5d8`">
            <div
              :class="`footer-delayed c-p ${
                videoItem.delayState ? 'color-FB8A4C' : ''
              }`"
              @click.stop="onDelayed(index)"
            >
              延时播放
            </div>
            <div
              :class="`footer-playback m-l-15 c-p ${
                videoItem.backState ? 'color-FB8A4C' : ''
              }`"
              @click.stop="onPlayBack(index)"
            >
              回放
            </div>
            <div class="footer-hd m-l-15 c-p" @click.stop="onHd(index)">
              <span
                :class="`${!videoItem.hdState ? 'color-FB8A4C' : ''}`"
              >标</span>/<span
                :class="`hd-color ${videoItem.hdState ? 'color-FB8A4C' : ''}`"
              >高清</span>
            </div>
          </div>
          <div class="footer-three f f-a-c">
            <el-tooltip
              :visible-arrow="true"
              effect="dark"
              :content="videoItem.micState ? '结束说话' : '开始说话'"
              placement="bottom"
            >
              <el-image
                :class="`mic-image c-p ${
                  videoItem.micState ? 'mic-image-ed' : ''
                }`"
                :src="`${
                  videoItem.micState
                    ? require('../assets/image/miced.png')
                    : require('../assets/image/mic.png')
                }`"
                :fit="'contain'"
                @click.stop="handleClick('mic', index)"
              />
            </el-tooltip>
            <el-tooltip
              :visible-arrow="true"
              effect="dark"
              :content="videoItem.recording ? '结束录制' : '开始录制'"
              placement="bottom"
            >
              <el-image
                :class="`record-image m-l-10 c-p`"
                :src="`${
                  videoItem.recording
                    ? require('../assets/image/Recordinged.png')
                    : require('../assets/image/Recording.png')
                }`"
                :fit="'contain'"
                @click.stop="handleClick('recording', index)"
              />
            </el-tooltip>
            <el-tooltip
              :visible-arrow="true"
              effect="dark"
              :content="videoItem.horn ? '关闭声音' : '开启声音'"
              placement="bottom"
            >
              <el-image
                :class="`horn-image m-l-10 c-p`"
                :src="`${
                  videoItem.horn
                    ? require('../assets/image/horned.png')
                    : require('../assets/image/horn.png')
                }`"
                :fit="'contain'"
                @click.stop="handleClick('horn', index)"
              />
            </el-tooltip>
            <el-tooltip
              :visible-arrow="true"
              effect="dark"
              :content="'截屏'"
              placement="bottom"
            >
              <el-image
                :class="`screenshot-image m-l-10 c-p`"
                :src="`${require('../assets/image/screenshot.png')}`"
                :fit="'contain'"
                @click.stop="handleClick('screenshot', index)"
              />
            </el-tooltip>
            <el-tooltip
              :visible-arrow="true"
              effect="dark"
              :content="'全屏播放'"
              placement="bottom"
            >
              <el-image
                :class="`full-image m-l-10 c-p`"
                :src="`${require('../assets/image/full.png')}`"
                :fit="'contain'"
                @click.stop="handleClick('full', index)"
              />
            </el-tooltip>
          </div>
        </div>
      </div>
      <!-- 视频录制动画 -->
      <div v-show="videoItem.recording" class="reco-load">
        <loading-recoding />
      </div>
    </div>
    <!-- 延时播放和回放对话框 -->
    <el-dialog
      append-to-body
      :title="dialogVisibleTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="40%"
      @close="close"
    >
      <div class="player-container">
        <!-- 回放 -->
        <template v-if="status">
          <div class="date-picker">
            <el-date-picker
              v-model="dateVal"
              :clearable="false"
              style="width: 60%"
              type="datetime"
              :picker-options="pickerOptions"
              placeholder="请选择开始回放的日期和时间"
              value-format="timestamp"
              @change="dateChange"
            />
          </div>
          <div v-if="dialogVisible" class="video-back f f-j-c f-a-c">
            <div v-if="dateVal" id="bakc-video-box" class="bakc-video" />
            <div v-else class="t-s">请选择开始回放的日期和时间!</div>
          </div>
        </template>
        <!-- 延时播放 https://media.vued.vanthink.cn/sparkle_your_name_am720p.mp4-->
        <template v-else>
          <vue-core-video-player
            v-if="dialogVisible"
            :src="videoUrl"
          />
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getDdelayedVideoUri, getVideoPlayBack } from '@/api/monitor'
import screenfull from 'screenfull'
import loadingRecoding from '@/components/loading-recoding'
export default {
  components: { loadingRecoding },
  props: {
    sign: {
      type: Boolean,
      default: false
    },
    // 监控外部盒子高度
    boxHeight: {
      type: Number,
      default: 0
    },
    boxMarginTop: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      videoUrl: '',
      status: false,
      dialogVisibleTitle: undefined,
      dialogVisible: false,
      dateVal: null,
      pickerOptions: {
        disabledDate(time) {
          // disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return time.getTime() > Date.now() // 选当前时间之前的时间
          // return time.getTime() < Date.now()//选当前时间之后的时间
        }
      },
      player: null,
      stopRefresh: false,
      videoItem: {}
    }
  },
  watch: {
    item: {
      handler(nval, oval) {
        if (Object.keys(nval).length === 0) return
        console.log('nval', nval)
        this.videoItem = {
          ...nval,
          delayState: false, // 延时播放状态
          backState: false, // 回放状态
          hdState: false, // 标高清状态(默认选中标清)
          micState: false, // 麦克风状态
          recording: false, // 录制视频状态
          horn: false // 喇叭状态
        }
        this.initPlay()
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      //   console.log("全屏状态:", screenfull.isFullscreen);
      //   console.log("this.stopRefresh", this.stopRefresh);
      if (this.stopRefresh) return
      if (!screenfull.isFullscreen && this.stopRefresh) {
        this.stopRefresh = false
        this.initPlay()
      }
    })
  },
  methods: {
    /**
     * 初始化监控播放
     */
    initPlay() {
      if (this.stopRefresh) return
      this.disposeVideo('myPlayer-video').then(() => {
        this.$nextTick(() => {
          setTimeout(() => {
            const playerEl = new EZUIKit.EZUIKitPlayer({
              id: 'monitor-box', // 视频容器ID
              accessToken: this.videoItem.accessToken,
              url: this.videoItem.url,
              plugin: ['talk'],
              width: 0,
              height: 0
            })
            this.player = playerEl
          }, 100)
        })
      })
    },
    /**
     * 销毁监控页面内容
     */
    disposeVideo(className) {
      return new Promise((resolve, reject) => {
        const videos = document.getElementsByClassName(className)
        videos.forEach((item, index) => {
          item.innerHTML = ''
        })
        resolve()
      })
    },
    /**
     * 延时播放事件
     * @param {Number} index 操作的监控索引
     */
    onDelayed(index) {
      this.stopRefresh = true
      this.videoItem.delayState = !this.videoItem.delayState
      this.dialogVisibleTitle = `【延时播放】- ${this.videoItem.name}`
      this.status = false
      getDdelayedVideoUri({
        sn: this.videoItem.sn,
        channelNo: this.videoItem.channelNo
      })
        .then((res) => {
          const { data } = res
          this.videoUrl = data
          this.dialogVisible = true
        })
        .catch((err) => {
          setTimeout(() => {
            this.videoItem.delayState = false
          }, 500)
        })
    },
    /**
     * 回放事件
     * @param {Number} index 操作的监控索引
     */
    onPlayBack(index) {
      this.stopRefresh = true
      this.videoItem.backState = !this.videoItem.backState
      this.dialogVisibleTitle = `【回放】- ${this.videoItem.name}`
      this.status = true
      this.dialogVisible = true
    },
    /**
     * 日期事件选择事件
     */
    dateChange(date) {
      this.disposeVideo('bakc-video').then(() => {
        getVideoPlayBack({
          id: this.videoItem.deviceId,
          begTime: date
        }).then((res) => {
          const { data } = res
          this.$nextTick(() => {
            setTimeout(() => {
              const playerEl = new EZUIKit.EZUIKitPlayer({
                id: 'bakc-video-box', // 视频容器ID
                accessToken: data.accessToken,
                url: data.url,
                width: 0,
                height: 0
              })
            }, 100)
          })
        })
      })
    },
    /**
     * 对话框关闭回调
     */
    close() {
      this.stopRefresh = true
      this.dateVal = null
      this.videoItem.delayState = false
      this.videoItem.backState = false
    },
    /**
     * 标/高清事件
     * @param {Number} index 操作的监控索引
     */
    onHd(index) {
      this.stopRefresh = true
      this.videoItem.hdState = !this.videoItem.hdState
      if (this.videoItem.hdState) {
        // 切换为高清
        this.player.stop()
        this.player.play({
          url: this.videoItem.hdUrl
        })
      } else {
        // 切换为标清
        this.player.stop()
        this.player.play({
          url: this.videoItem.url
        })
      }
    },
    /**
     * 监控图标操作点击事件
     *  @param {String} type 图标类型
     *  @param {Number} index 索引
     */
    handleClick(type, index) {
      switch (type) {
        case 'mic': // 麦克风
          this.stopRefresh = true
          this.videoItem.micState = !this.videoItem.micState
          if (this.videoItem.micState) {
            this.player.startTalk() // 开始说话
          } else {
            this.player.stopTalk() // 结束说话
          }
          break
        case 'recording': // 录制视频
          this.stopRefresh = true
          this.videoItem.recording = !this.videoItem.recording
          if (this.videoItem.recording) {
            this.player.startSave() // 开始录制
          } else {
            this.player.stopSave() // 结束录制
          }
          break
        case 'horn': // 喇叭
          this.stopRefresh = true
          this.videoItem.horn = !this.videoItem.horn
          if (this.videoItem.horn) {
            this.player.openSound() // 开启声音
          } else {
            this.player.closeSound() // 关闭声音
          }
          break
        case 'screenshot': // 截屏
          this.player.capturePicture() // 视频截图
          break
        case 'full': // 全屏
          this.stopRefresh = true
          this.player.cancelFullScreen() // 先取消全屏
          this.$nextTick(() => {
            setTimeout(() => {
              this.player.fullScreen() // 开启全屏
            }, 100)
          })
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  border: none;
  background: rgba(4, 32, 89, 0.92) !important;
}
::v-deep input::-webkit-input-placeholder {
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #41a5d8 !important;
}
::v-deep .el-icon-time {
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #41a5d8 !important;
}
::v-deep .el-icon-circle-close {
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #41a5d8 !important;
}
::v-deep .el-dialog__header {
  background: rgba(4, 32, 89, 0.92) !important;
}
::v-deep .el-dialog__title {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #41a5d8 !important;
}
::v-deep .el-icon-close {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #41a5d8 !important;
}
::v-deep .el-button {
  border: rgba(4, 32, 89, 0) !important;
  background: rgba(4, 32, 89, 0) !important;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #41a5d8 !important;
}
::v-deep .el-dialog__body {
  background: rgba(4, 32, 89, 0.92) !important;
}
::v-deep .el-dialog__footer {
  background: rgba(4, 32, 89, 0.92) !important;
}
.yjg-padding {
  padding: 6px;
}
.zc-padding {
  padding: 11px 14px;
}
.video-item {
  width: auto;
  background: rgba(11, 38, 92, 0.04);
  border: 1px solid rgba(15, 128, 205, 1);
  box-shadow: 0 0 15px #666666 inset;
  opacity: 0.8;
  position: relative;
}
.myPlayer-video {
  width: calc(100%);
  height: calc(100%);
}
.yjg-dw {
  position: absolute;
  bottom: 6px;
  left: 6px;
  right: 6px;
}
.zc-dw {
  position: absolute;
  bottom: 11px;
  left: 14px;
  right: 14px;
}
.video-footer {
  height: 50px;
  background: rgba(10, 10, 10, 0.92);
  .footer-title {
    max-width: 540px;
    margin-left: 10px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }
  .footer-two {
    margin-left: 10px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    .m-l-15 {
      margin-left: 15px;
    }
  }
  .footer-three {
    margin-left: 10px;
    margin-right: 10px;
    .mic-image {
      width: 24px;
      height: 21px;
    }
    .mic-image-ed {
      width: 24px;
      height: 35px;
    }
    .record-image {
      width: 24px;
      height: 24px;
    }
    .horn-image {
      width: 17px;
      height: 17px;
    }
    .screenshot-image {
      width: 18px;
      height: 18px;
    }
    .full-image {
      width: 15px;
      height: 15px;
    }
    .m-l-10 {
      margin-left: 10px;
    }
  }
}
.reco-load {
  position: absolute;
  top: -25px;
  left: 25px;
}

.video-back {
  width: 700px;
  height: 346px;
  padding: 11px 14px;
  margin-top: 13px;
  background: rgba(11, 38, 92, 0.04);
  border: 1px solid rgba(15, 128, 205, 1);
  box-shadow: 0 0 15px #666666 inset;
  opacity: 0.8;
  position: relative;
}
.t-s {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #41a5d8 !important;
}
.bakc-video {
  width: 700px;
  height: 346px;
}
.date-picker {
  margin-bottom: 20px;
}
</style>
