import request from '@/utils/request'

export function getVideo(params) {
  return request({
    url: '/Device/GetVideoDevices',
    method: 'get',
    params
  })
}

export function getDdelayedVideoUri(params) {
  return request({
    url: '/Device/GetDdelayedVideoUri',
    method: 'get',
    params
  })
}

export function getVideoPlayBack(data) {
  return request({
    url: '/Device/GetVideoPlayBack',
    method: 'post',
    data
  })
}
